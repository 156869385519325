"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";
import React from "react";
import Link from "src/components/common/Link/Link";

export interface SiteFooterProps {
  className?: string;
}

const FOOTER_PAGES = [
  { label: "Privacy Policy", href: "/privacy-policy" },
  { label: "Cookies", href: "/cookies" },
  { label: "Terms of Service", href: "/terms" },
  { label: "Help", href: "/help" },
];

const SiteFooter = (props: SiteFooterProps) => {
  const { className } = props;

  const pathname = usePathname();

  const filteredPages = FOOTER_PAGES.filter((page) => page.href !== pathname);

  return (
    <footer className={clsx("flex justify-center items-end", className)}>
      <div className={"text-center"}>
        <div className={"text-sm"}>
          {filteredPages.map((page, index) => (
            <React.Fragment key={page.href}>
              {index === 0 ? null : (
                <span className={clsx("text-mutedText", index === 3 ? "mobile:hidden" : undefined)}>
                  {" "}
                  |{" "}
                </span>
              )}
              {index === 3 ? <br className={"desktop:hidden"} /> : null}
              <Link href={page.href}>{page.label}</Link>
            </React.Fragment>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
