"use client";
import React, { useEffect } from "react";
import { type PlanType, getPlanTypeInfo } from "shared/Plans";
import type { AuthState } from "shared/SharedTypes";
import { postAuthSignOut } from "src/Api";
import { setQueryParam } from "src/lib/MiscFrontendUtils";
import { onNextRouteChangeOnce } from "src/lib/RouterChangeListener";
import * as Sentry from "src/lib/Sentry";
import { clearClientAuthCacheAction } from "src/lib/serverAction/ClearAuthCacheAction";

export interface AuthContextState {
  signIn: (state: AuthState) => Promise<void>;
  signOut: () => Promise<void>;
  authState: AuthState | undefined;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState | undefined>>;
}

const AuthContext = React.createContext<AuthContextState | undefined>(undefined);

export interface AuthProviderProps {
  children: React.ReactNode;
  defaultAuthState: AuthState | undefined | null;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { children, defaultAuthState } = props;
  const [authState, setAuthState] = React.useState<AuthState | undefined>(
    defaultAuthState ?? undefined,
  );

  useEffect(() => {
    if (authState?.user) {
      Sentry.setUser({
        id: authState.user.id,
        username: `${authState.user.firstName} ${authState.user.lastName}`,
        email: authState.user.email,
      });
    }
  }, [authState]);

  return (
    <AuthContext.Provider
      value={{
        authState: authState,
        setAuthState,
        signIn: async (state: AuthState) => {
          setAuthState(state);
          await clearClientAuthCacheAction();
        },
        signOut: async () => {
          setQueryParam("transition", "loggingOut");
          setAuthState(undefined);
          await postAuthSignOut.submit({});
          await clearClientAuthCacheAction();
          onNextRouteChangeOnce(() => {
            setQueryParam("transition", null);
          });
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextState | undefined => {
  const context = React.useContext(AuthContext);
  return context;
};

export const useCurrentPlan = (): PlanType | null => {
  const auth = useAuth();
  return auth?.authState?.subscription?.planTypeId
    ? getPlanTypeInfo(auth.authState.subscription.planTypeId)
    : null;
};

export default AuthContext;
