"use client";

import clsx from "clsx";
import type React from "react";
import Popover, {
  type PopoverContentProps,
  type PopoverProps,
  type PopoverTriggerProps,
} from "src/components/common/Popover/Popover";
import { useVisibility } from "src/lib/Visibility";
import styles from "./Tooltip.module.scss";

export interface TooltipProps extends PopoverProps {}

const Tooltip = (props: TooltipProps) => {
  const { children, visible, ...otherProps } = props;

  const { visible: contextVisible } = useVisibility();

  return (
    <Popover visible={!contextVisible ? false : visible} {...otherProps}>
      {children}
    </Popover>
  );
};

export type TooltipTriggerProps<C extends React.ElementType = "button"> = PopoverTriggerProps<C>;

const TooltipTrigger = <C extends React.ElementType = "button">(props: TooltipTriggerProps<C>) => {
  const { ...otherProps } = props;

  return <Popover.Trigger {...otherProps} />;
};

export type TooltipContentProps = PopoverContentProps;

const TooltipContent = (props: TooltipContentProps) => {
  const { className, children, ...otherProps } = props;

  return (
    <Popover.Content
      className={clsx(
        styles.tooltipContent,
        "bg-background overflow-hidden rounded-md bg-popover px-3 py-1.5 font-normal text-popover-foreground shadow-md",
        className,
      )}
      {...otherProps}
    >
      {children}
    </Popover.Content>
  );
};

export default Object.assign(Tooltip, {
  Trigger: TooltipTrigger,
  Content: TooltipContent,
});
