"use client";

import {
  type OpenChangeReason,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { composeRefs } from "@radix-ui/react-compose-refs";
import { Slot } from "@radix-ui/react-slot";
import clsx from "clsx";
import React, { useCallback, useImperativeHandle, useState } from "react";
import type { CommonHtmlProps } from "shared/SharedTypes";
import { VisibilityProvider } from "src/lib/Visibility";
import {
  PopoverContext,
  type PopoverContextState,
  usePopoverFloating,
} from "src/lib/state/PopoverContext";

const DEFAULT_TRIGGER: TriggerType[] = ["hoverIn", "hoverOut", "focus", "blur"];

export type TriggerType = "hoverIn" | "hoverOut" | "focus" | "click" | "blur" | "keyboard";
export type PopoverHandle = PopoverContextState;

export type VisibleChangeReason = OpenChangeReason;

export interface PopoverProps {
  visible?: boolean;
  onVisibleChange?: (value: boolean, event?: Event, reason?: VisibleChangeReason) => void;
  children?: React.ReactNode | ((state: PopoverContextState) => React.ReactNode);
  disableInteractions?: boolean;
  fill?: boolean;
  triggers?: Array<TriggerType | false | null | undefined>;
  ref?: React.Ref<PopoverHandle>;
  overlay?: boolean;
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "top-start"
    | "top-end"
    | "right-start"
    | "right-end"
    | "bottom-start"
    | "bottom-end"
    | "left-start"
    | "left-end";
}

const Popover = (props: PopoverProps) => {
  const {
    disableInteractions,
    onVisibleChange,
    placement,
    fill,
    ref,
    children,
    triggers,
    visible: propsVisible,
  } = props;
  const [visible, _setVisible] = React.useState(false);

  const resolvedTriggers = triggers ?? DEFAULT_TRIGGER;
  const setVisible = (nextVisible: boolean, event?: any, reason?: VisibleChangeReason) => {
    if (reason) {
      let type: TriggerType | undefined;
      if (reason === "click") {
        type = "click";
      } else if (reason === "hover") {
        type = nextVisible ? "hoverIn" : "hoverOut";
      } else if (reason === "focus") {
        type = nextVisible ? "focus" : "blur";
      } else if (reason === "outside-press") {
        type = "blur";
      } else if (reason === "focus-out") {
        type = "blur";
      }

      if (!type || !resolvedTriggers.includes(type)) return;
    }

    _setVisible(nextVisible);
    onVisibleChange?.(nextVisible, event, reason);
  };

  const currentlyVisible = propsVisible ?? visible;

  const extraStylesRef = React.useRef<React.CSSProperties>({});

  const floating = useFloating({
    //open: true,
    open: currentlyVisible,
    onOpenChange: setVisible,
    strategy: "fixed",
    placement: placement ?? "bottom",

    // on hover

    middleware: [
      offset(10),
      flip({
        padding: 10,
        boundary: "clippingAncestors",
        fallbackStrategy: "bestFit",
      }),
      shift({
        padding: 10,
        boundary: "clippingAncestors",
        crossAxis: true,
      }),
      size({
        apply(state) {
          if (fill) {
            const axis =
              state.placement === "top" || state.placement === "bottom" ? "width" : "height";
            // @ts-ignore
            state.elements.floating.style[axis] = state.rects.reference[axis];
            extraStylesRef.current[axis] = state.rects.reference[axis];
          }
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(floating.context, {
    enabled:
      !disableInteractions &&
      (resolvedTriggers.includes("hoverIn") || resolvedTriggers.includes("hoverOut")),
    delay: {
      open: 500,
    },
  });
  const focus = useFocus(floating.context, {
    enabled:
      !disableInteractions &&
      (resolvedTriggers.includes("focus") || resolvedTriggers.includes("blur")),
    //delay: 1000,
  });
  const click = useClick(floating.context, {
    enabled:
      !disableInteractions &&
      (resolvedTriggers.includes("click") || resolvedTriggers.includes("keyboard")),
    ignoreMouse: !resolvedTriggers.includes("click"),
    keyboardHandlers: resolvedTriggers.includes("keyboard"),
  });
  const dismiss = useDismiss(floating.context);

  const transitionStyles = useTransitionStyles(floating.context, {});
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, click, dismiss]);
  const context: PopoverContextState = {
    ...floating.context,
    // @ts-ignore
    open: undefined,
    onOpenChange: undefined,
    onVisibleChange: floating.context.onOpenChange,
    setVisibleChange: floating.context.onOpenChange,
    visible: currentlyVisible,
    floatingStyles: {
      ...transitionStyles.styles,
      ...floating.context.floatingStyles,
      ...extraStylesRef.current,
    },
    refs: {
      ...floating.context.refs,
      setFloating: (element) => {
        floating.context.refs.setFloating(element);
      },
    },
    getFloatingProps: () => ({
      ...getFloatingProps(),
      "data-popover": true,
      "data-popover-visible": floating.context.open,
      inert: !floating.context.open,
      "data-popover-placement": floating.context.placement,
    }),
    getReferenceProps,
  };

  useImperativeHandle(ref, () => context);

  return (
    <PopoverContext.Provider value={context}>
      {typeof children === "function" ? children(context) : children}
    </PopoverContext.Provider>
  );
};

export type PopoverTriggerProps<C extends React.ElementType = "button"> =
  React.ComponentProps<C> & {
    as?: C;
    ref?: React.Ref<any>;
    asChild?: boolean;
  };

const PopoverTrigger = <C extends React.ElementType = "button">(props: PopoverTriggerProps<C>) => {
  const { asChild, ref, as, ...otherProps } = props;
  const Comp: any = asChild ? Slot : (as ?? "button");
  const popoverContext = React.useContext(PopoverContext);

  return (
    <Comp
      ref={composeRefs(ref, popoverContext?.refs.setReference)}
      {...popoverContext?.getReferenceProps?.()}
      {...otherProps}
    />
  );
};

export type PopoverContentProps = CommonHtmlProps & {
  asChild?: boolean;
};

const PopoverContent = (props: PopoverContentProps) => {
  const { children, ref, asChild, className, style, ...otherProps } = props;

  const floating = usePopoverFloating();
  if (!floating) {
    return null;
  }

  const { ref: floatingRef, ...floatingProps } = floating.props;

  const Tag = asChild ? Slot : "div";

  return floating.render(
    <VisibilityProvider value={{ visible: floating.context.visible }}>
      <Tag
        {...floatingProps}
        // @ts-ignore
        ref={composeRefs(floatingRef, ref)}
        style={{ ...floating.props.style, ...style }}
        className={clsx("z-[10000] no-focus-ring", className)}
        {...otherProps}
      >
        {children}
      </Tag>
    </VisibilityProvider>,
  );
};

/*export function withPopover<T extends React.ComponentType<any> | keyof HTMLElementTagNameMap>(
  Component: T,
) {
  return React.forwardRef<
    React.ElementRef<T>,
    React.ComponentPropsWithoutRef<T> & {
      popover?: React.ReactNode;
      popoverContentProps?: Omit<React.ComponentPropsWithoutRef<typeof Content>, "children">;
      popoverProps?: Omit<React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root>, "children">;
    }
  >(function ExtendComponent({ popover, popoverContentProps, popoverProps, ref, ...props }) {
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
      setMounted(true);
    }, []);

    const component = <Component ref={ref} {...(props as any)} />;

    if (popover && mounted) {
      return (
        <Popover {...popoverProps}>
          <PopoverTrigger asChild>{component}</PopoverTrigger>

          <PopoverPortal>
            <PopoverContent {...popoverContentProps}>{popover}</PopoverContent>
          </PopoverPortal>
        </Popover>
      );
    }

    return component;
  });
}*/

export const useVisibleState = () => {
  const [visible, setVisible] = useState(false);

  const onVisibleChange = useCallback(
    (_value = !visible) => {
      setVisible(_value);
    },
    [visible],
  );

  return {
    visible,
    onVisibleChange,
  };
};

export default Object.assign(Popover, {
  Trigger: PopoverTrigger,
  Content: PopoverContent,
});
