"use client";

import { composeRefs } from "@radix-ui/react-compose-refs";
import { Slottable } from "@radix-ui/react-slot";
import * as BaseTabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import Ripple from "src/components/common/Ripple/Ripple";
import { afterInitialRender, isInitialRenderDone } from "src/lib/ClientHooks";
import styles from "./Tabs.module.scss";

export interface TabsTriggerProps extends BaseTabs.TabsTriggerProps {
  ref?: React.Ref<HTMLButtonElement>;
}

export const TabsTrigger = (props: TabsTriggerProps) => {
  const { children, ref, className, value, ...otherProps } = props;

  const tabsContext = useContext(TabsContext);

  const selected = tabsContext.value === value;

  const [ssr, setSsr] = React.useState(!isInitialRenderDone());
  afterInitialRender(() => {
    setSsr(false);
  });
  const ownRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (selected) {
      ownRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selected]);

  return (
    <BaseTabs.Trigger
      ref={composeRefs(ownRef, ref)}
      value={value}
      className={clsx(styles.tab, className)}
      {...otherProps}
    >
      <Ripple />
      <Slottable>{children}</Slottable>
      {ssr && selected && <div className={clsx(styles.indicatorBar, styles.ssr)} />}
    </BaseTabs.Trigger>
  );
};

export interface TabsContentProps extends BaseTabs.TabsContentProps {}

export const TabsContent = (props: TabsContentProps) => {
  const { children, ...otherProps } = props;
  return (
    <BaseTabs.TabsContent
      // @ts-ignore
      tabIndex={null}
      {...otherProps}
    >
      {children}
    </BaseTabs.TabsContent>
  );
};

export interface TabsListProps extends BaseTabs.TabsListProps {
  indicatorBar?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

export const TabsList = (props: TabsListProps) => {
  const { indicatorBar, ref, className, children, ...otherProps } = props;

  const selfRef = useRef<HTMLDivElement>(null);
  const indicatorBarRef = useRef<HTMLDivElement>(null);

  const selectedTab = selfRef.current?.querySelector(
    "[role='tab'][data-state='active']",
  ) as HTMLElement;
  if (selectedTab && indicatorBarRef.current) {
    indicatorBarRef.current.style.width = `${selectedTab.offsetWidth}px`;
    indicatorBarRef.current.style.transform = `translateX(${selectedTab.offsetLeft}px)`;
  }

  useLayoutEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      const selectedTab = selfRef.current?.querySelector(
        "[role='tab'][data-state='active']",
      ) as HTMLElement;
      if (indicatorBarRef.current) {
        if (selectedTab) {
          indicatorBarRef.current.style.width = `${selectedTab.offsetWidth}px`;
          indicatorBarRef.current.style.transform = `translateX(${selectedTab.offsetLeft}px)`;
          indicatorBarRef.current.style.opacity = "1";
        } else {
          indicatorBarRef.current.style.opacity = "0";
        }
        indicatorBarRef.current.style.display = "block";
      }
    });
    if (selfRef.current) {
      mutationObserver.observe(selfRef.current, {
        subtree: true,
        attributes: true,
      });
    }
    return () => mutationObserver.disconnect();
  }, []);

  return (
    <BaseTabs.TabsList
      ref={composeRefs(selfRef, ref)}
      className={clsx("relative", className)}
      {...otherProps}
    >
      {children}
      {indicatorBar && <div ref={indicatorBarRef} className={styles.indicatorBar} />}
    </BaseTabs.TabsList>
  );
};

export interface TabsProps extends Omit<BaseTabs.TabsProps, "value"> {
  variant?: "bare" | "normal" | "pill";
  value?: string | null;
}

export interface TabsContextState {
  value: any;
}

const TabsContext = React.createContext<TabsContextState>({ value: undefined });

const Tabs = (props: TabsProps) => {
  const { variant, value, className, ...otherProps } = props;
  return (
    <TabsContext.Provider value={{ value }}>
      <BaseTabs.Root
        value={value as any}
        className={clsx(styles.tabs, styles[`variant-${variant ?? "normal"}`], className)}
        {...otherProps}
      />
    </TabsContext.Provider>
  );
};

export default Object.assign(Tabs, { Trigger: TabsTrigger, Content: TabsContent, List: TabsList });
