"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { composeRefs } from "@radix-ui/react-compose-refs";
import type { FormProps as BaseFormProps } from "@radix-ui/react-form";
import { get } from "object-path";
import React, { useEffect, useImperativeHandle } from "react";
import type { FieldValues, FormState, ResolverResult } from "react-hook-form";
import { type Resolver, useForm as useHookForm } from "react-hook-form";
import { deepClone } from "shared/SharedUtils";
import { useForceUpdate, useUpdateEffect } from "src/lib/ClientHooks";
import { createEvent } from "src/lib/EventUtils";
import { isDeepEqual } from "src/lib/MiscFrontendUtils";
import type * as zod from "zod";

export interface FieldChange {
  name: string | undefined;
  value: any;
}

export interface FormControllerHandle {
  submit: () => Promise<boolean>;
  validate: () => Promise<boolean>;
  elementRef: React.Ref<HTMLFormElement>;
}

export interface FormProps<T extends FieldValues>
  extends Omit<BaseFormProps, "onSubmit" | "onChange" | "value" | "defaultValue"> {
  ref?: React.Ref<HTMLFormElement>;
  controllerRef?: React.Ref<FormControllerHandle>;
  validate?: Resolver<T>;
  validationSchema?: zod.ZodType<T>;
  onSubmit?: (data: FieldValues, event: React.FormEvent<HTMLFormElement>) => void;
  defaultValue?: any;
  value?: any;
  disabled?: boolean;
  onChange?: (values: FieldValues, changed: FieldChange) => void;
  resetKey?: string | number;
}

export type FormContextState = Omit<ReturnType<typeof useHookForm>, "formState"> & {};

const FormContext = React.createContext<FormContextState | undefined>(undefined);

export const ResetFormContextProvider = ({ children }: { children: React.ReactNode }) => {
  return <FormContext.Provider value={undefined}>{children}</FormContext.Provider>;
};

const mergeResolverResults = (a: ResolverResult, b: ResolverResult): ResolverResult => {
  return {
    errors: {
      ...a.errors,
      ...b.errors,
    },
    values: {
      ...a.values,
      ...b.values,
    },
  };
};

const Form = <T extends FieldValues>(props: FormProps<T>) => {
  const {
    resetKey,
    children,
    ref,
    controllerRef,
    value,
    disabled,
    defaultValue,
    onSubmit,
    onChange,
    validationSchema,
    validate,
    ...otherProps
  } = props;
  const zodResolverInstance = validationSchema ? zodResolver(validationSchema) : undefined;

  const form = useHookForm({
    defaultValues: defaultValue ?? value,
    values: value,
    disabled,
    mode: "onChange",
    resolver: async (data, context, options) => {
      let result = {
        errors: {},
        values: {},
      } satisfies ResolverResult;
      if (zodResolverInstance) {
        result = mergeResolverResults(result, await zodResolverInstance(data, context, options));
      }
      if (validate) {
        result = mergeResolverResults(result, await validate(data, context, options));
      }

      const formValues = { ...form.getValues(), ...result.values };
      for (const [id, field] of Object.entries(options.fields)) {
        if (typeof field.validate === "function") {
          const value = get(formValues, id);
          const error = await field.validate(value, formValues);
          if (typeof error === "string") {
            // @ts-ignore
            result.errors[id] = {
              type: "custom",
              message: error,
            };
          }
        }
      }

      return result;
    },
  });

  const [forceUpdate] = useForceUpdate();

  useUpdateEffect(() => {
    form.reset(value ?? defaultValue);
  }, [resetKey]);

  const previousValuesRef = React.useRef<FieldValues | undefined>(form.getValues());

  useEffect(() => {
    const sub = form.watch((values, change) => {
      if (change.name) {
        const previousValue =
          previousValuesRef.current && get(previousValuesRef.current, change.name);
        const newValue = get(values, change.name);
        if (!isDeepEqual(previousValue, newValue)) {
          onChange?.(values, {
            name: change.name,
            value: newValue,
          });
        }
      }

      if (!change.name) {
        forceUpdate();
      }
      previousValuesRef.current = deepClone(values);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const selfRef = React.useRef<HTMLFormElement>(null);

  useImperativeHandle(controllerRef, () => ({
    submit: async () => {
      const formElement = selfRef.current;
      if (!formElement) {
        return false;
      }
      return new Promise((resolve, reject) => {
        const formSubmitEvent = createEvent("submit", formElement);
        form.handleSubmit(
          (data, event) => {
            if (onSubmit) {
              onSubmit(data as T, event as unknown as React.FormEvent<HTMLFormElement>);
            }
            resolve(true);
          },
          () => {
            resolve(false);
          },
        )(formSubmitEvent);
      });
    },
    validate: async () => {
      return await form.trigger();
    },
    elementRef: selfRef,
  }));

  return (
    <FormContext.Provider value={form}>
      <form
        ref={composeRefs(selfRef, ref)}
        noValidate={true}
        onSubmit={form.handleSubmit((data, event) => {
          if (onSubmit) {
            onSubmit(form.getValues() as T, event as unknown as React.FormEvent<HTMLFormElement>);
          }
        })}
        {...otherProps}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
};

export const useForm = () => {
  const form = React.useContext(FormContext);
  return form;
};

interface Subject<T> {
  subscribe: (observer: { next: (value: T) => void }) => { unsubscribe: () => void };
}

type SubscribeProps<T> = {
  disabled?: boolean;
  subject: Subject<T>;
  next: (value: T) => void;
};

export function useSubscribe<T>(props: SubscribeProps<T>) {
  const _props = React.useRef(props);
  _props.current = props;

  React.useEffect(() => {
    const subscription =
      !props.disabled &&
      _props.current.subject &&
      _props.current.subject.subscribe({
        next: _props.current.next,
      });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [props.disabled]);
}

export const useFormState = <R,>(selector?: (state: FormState<any>) => R): R => {
  const form = React.useContext(FormContext);

  const selectState = (newState?: any) => {
    if (!form) {
      return undefined;
    }
    const state: any = {};
    for (const key of Object.getOwnPropertyNames((form as any).formState)) {
      state[key] = (form as any).formState[key];
    }
    Object.assign(state, newState);
    if (selector) {
      return selector(state);
    }
    return state;
  };

  const [state, setState] = React.useState<R>(() => selectState());

  const maybeUpdate = (stateUpdate?: any) => {
    const newState = selectState(stateUpdate);
    if (isDeepEqual(newState, state)) {
      return;
    }
    setState(newState);
  };

  useSubscribe({
    next: (stateUpdate) => {
      maybeUpdate(stateUpdate);
    },
    // @ts-ignore
    subject: form?.control._subjects.state,
  });

  return state;
};

export default Form;
