"use client";

import { usePathname } from "next/navigation";
import Tabs, { type TabsProps } from "../../common/Tabs/Tabs";

export interface NavTabsProps extends Omit<TabsProps, "value" | "defaultValue"> {}

const NavTabs = (props: NavTabsProps) => {
  const { children, ...otherProps } = props;
  const pathname = usePathname();
  return (
    <Tabs value={pathname} asChild={true} {...otherProps}>
      <nav>{children}</nav>
    </Tabs>
  );
};

export default NavTabs;
