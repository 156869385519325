import { cva } from "class-variance-authority";

export {
  IconMapPinFilled as IconMapPin,
  IconPlus as IconAdd,
  IconAlignCenter,
  IconAlignJustified as IconAlignJustify,
  IconAlignLeft,
  IconAlignRight,
  IconChevronDown as IconArrowDown,
  IconPaint as IconBg,
  IconQuote as IconBlockQuote,
  IconBold,
  IconBorderAll,
  IconBorderBottom,
  IconBorderLeft,
  IconBorderNone,
  IconBackground,
  IconBorderRight,
  IconBorderTop,
  IconBrandFacebook as IconFacebook,
  IconBrandTwitch as IconTwitch,
  IconBrandLinkedin as IconLinkedin,
  IconBrandSoundcloud as IconSoundcloud,
  IconCheck,
  IconAnalyze,
  IconBolt,
  IconGitFork,
  IconPaint,
  IconChevronRight,
  IconClick,
  IconSelector as IconChevronsUpDown,
  IconX as IconClear,
  IconX as IconClose,
  IconCode,
  IconFileCode as IconCodeblock,
  IconBaseline as IconColor,
  IconRectangleVertical as IconColumn,
  IconTrash as IconDelete,
  IconGripVertical as IconDragHandle,
  IconArrowLeft as IconBack,
  IconEdit as IconEditing,
  IconMoodSmile as IconEmoji,
  IconExternalLink,
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconH5,
  IconH6,
  IconPhoto as IconImage,
  IconIndentIncrease as IconIndent,
  IconItalic,
  IconKeyboard as IconKbd,
  IconTextWrap as IconLineHeight,
  IconLink,
  IconMinus,
  IconDots as IconMore,
  IconList as IconOl,
  IconIndentDecrease as IconOutdent,
  IconPilcrow as IconParagraph,
  IconRefresh,
  IconTableRow as IconRow,
  IconSearch,
  IconSettings,
  IconStrikethrough,
  IconSubscript,
  IconCircle,
  IconSuperscript,
  IconTable,
  IconTextSize as IconText,
  IconTrash,
  IconList as IconUl,
  IconNews as IcoNnewspaper,
  IconUnderline,
  IconUnlink,
  IconEye as IconViewing,
  IconDeviceMobile as IconMobile,
  IconDeviceDesktop as IconDesktop,
  IconBrandYoutube as IconYoutube,
  IconBrandGoogleFilled as IconGoogle,
  IconBrandApple as IconApple,
  IconBrandPinterest as IconPinterest,
  IconBrandInstagram as IconInstagram,
  IconCheck as IconSuccess,
  IconExclamationMark as IconWarning,
  IconX as IconError,
  IconBrandTiktok as IconTiktok,
  IconBrandSpotify as IconSpotify,
  IconBrandGithub as IconGitHub,
  IconMoon,
  IconSun,
  IconBrandX as IconTwitter,
  IconLibraryPhoto as IconTenor,
  IconTextSize as IconFonts,
  IconEye as IconShow,
  IconEyeOff as IconHide,
  IconBrush as IconDrawing,
  IconShare,
  IconWorldShare as IconPublish,
  IconWorldOff as IconUnpublish,
  IconCopy,
  IconPhoto as IconPresetSwap,
  IconHeading,
  IconHome,
  IconEdit,
  IconIcons as IconCategoryContent,
  IconSpeakerphone as IconCategorySocial,
  IconPhoto as IconCategoryMedia,
  IconApps as IconAddBoard,
  IconLayoutDashboard as IconBoards,
  IconRating18Plus as IconNsfw,
  IconLayersLinked as IconLinks,
  IconLayoutBoard as IconBoard,
  IconBrush as IconTheme,
  IconArrowsDiff as IconSwap,
  IconChevronDown,
  IconMessageQuestion as IconAMA,
  IconQuestionMark as IconInfo,
  IconInfoCircle,
  IconAlertOctagon as IconErrorCircle,
  IconAlertCircle as IconWarningCircle,
  IconCircleCheck as IconSuccessCircle,
  IconPhotoX as IconInappropriateImage,
  IconBackground as IconBackgroundPatterns,
  IconSquare,
  IconFileAlert as IconFileError,
  IconUserSquare as IconProfilePicture,
  IconChessKing as IconPrimary,
  IconChessQueen as IconSecondary,
  IconChessBishop as IconAccent,
  IconLineDashed as IconIndeterminate,
  IconEdit as IconChange,
  IconLocation,
  IconCurrentLocation,
  IconChevronLeft,
  IconRuler as IconFill,
  IconRulerMeasure as IconCover,
  IconRulerMeasure2 as IconContain,
  IconMail as IconEmail,
  IconPhone,
} from "@tabler/icons-react";

export const iconVariants = cva("", {
  variants: {
    variant: {
      toolbar: "size-5",
      menuItem: "mr-2 size-5",
    },
    size: {
      sm: "mr-2 size-4",
      md: "mr-2 size-6",
    },
  },
  defaultVariants: {},
});
