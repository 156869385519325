import HolyLoader from "holy-loader";
import type React from "react";
import { APP_THEME } from "shared/Theme";
import BuildWatermark from "src/components/app/BuildWatermark/BuildWatermark";
import { useThemedProps } from "src/components/app/ThemeProvider/ThemeProvider";

export interface AppLayoutProps {
  children: React.ReactNode;
  bodyProps?: React.HTMLAttributes<HTMLBodyElement>;
}

const AppLayout = (props: AppLayoutProps) => {
  const { children, bodyProps } = props;
  const themedProps = useThemedProps({ theme: APP_THEME, root: true });
  return (
    <html lang="en">
      <head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content={APP_THEME.primaryColor} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </head>
      <body {...bodyProps} {...themedProps}>
        {themedProps.children}
        <HolyLoader color={APP_THEME.primaryColor} height={3} />
        {children}
        {process.env.NEXT_PUBLIC_BUILD_WATERMARK !== "false" ? <BuildWatermark /> : null}
      </body>
    </html>
  );
};
export default AppLayout;
