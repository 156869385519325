import type { FloatingContext } from "@floating-ui/react";
import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { VisibilityProvider } from "src/lib/Visibility";

export interface PopoverContextState extends Omit<FloatingContext, "open" | "onOpenChange"> {
  getReferenceProps?: () => React.ComponentPropsWithoutRef<"div">;
  getFloatingProps?: () => React.ComponentPropsWithoutRef<"div">;
  visible: boolean;
  onVisibleChange: (value: boolean) => void;
}

export const PopoverContext = React.createContext<PopoverContextState | undefined>(undefined);

export const usePopover = () => {
  const popoverContext = React.useContext(PopoverContext);
  return popoverContext;
};

export const usePopoverFloating = (options?: { portal?: boolean }) => {
  const popoverContext = React.useContext(PopoverContext);

  const everVisible = useRef(false);

  if (!popoverContext) {
    return null;
  }

  if (popoverContext.visible && !everVisible.current) everVisible.current = true;

  return {
    context: popoverContext,
    props: {
      ref: popoverContext.refs.setFloating,
      style: popoverContext.floatingStyles,
      "data-popover-visible": popoverContext.visible,
      ...popoverContext.getFloatingProps?.(),
    },
    render: (children: React.ReactNode) => {
      const popoverContainer =
        typeof document === "undefined" ? null : document.querySelector("[data-popover-container]");

      return (
        <VisibilityProvider value={{ visible: popoverContext.visible }}>
          {everVisible.current
            ? options?.portal !== false
              ? popoverContainer
                ? createPortal(children, popoverContainer)
                : null
              : children
            : null}
        </VisibilityProvider>
      );
    },
  };
};
