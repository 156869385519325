import Link from "next/link";
import type React from "react";
import Logo from "src/components/app/Logo/Logo";
import SiteFooter from "src/components/app/SiteFooter/SiteFooter";
import StatusPanel from "src/components/app/StatusPanel";

export interface StatusPageProps {
  children?: React.ReactNode;
  title: string;
}

const StatusPage = (props: StatusPageProps) => {
  const { children, title } = props;
  return (
    <div className={"flex flex-col h-full min-h-[100vh] items-center justify-between"}>
      <title>{title}</title>
      <header className={"flex gap-2 p-10"}>
        <Link href={"/"}>
          <Logo className={"text-4xl self-center"} />
        </Link>
      </header>
      <StatusPanel title={title} asChild={true}>
        <main>{children}</main>
      </StatusPanel>
      <SiteFooter className={"p-10"} />
    </div>
  );
};

export default StatusPage;
