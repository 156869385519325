import clsx from "clsx";
import type React from "react";
import styles from "./Overlay.module.scss";

export interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean;
}

const Overlay = (props: OverlayProps) => {
  const { visible, className, ...otherProps } = props;
  return <div {...otherProps} className={clsx(styles.overlay, className)} data-visible={visible} />;
};

export default Overlay;
