"use client";

import { startHolyLoader } from "holy-loader";
import { useRouter } from "next/navigation";
import React, { useState } from "react";
import Button, { type ButtonProps } from "src/components/common/Button/Button";
import { useAuth } from "src/lib/state/AuthContext";

export interface SignOutButtonProps extends Partial<ButtonProps> {}

const SignOutButton = (props: SignOutButtonProps) => {
  const { onClick, ...otherProps } = props;

  const auth = useAuth();
  const router = useRouter();
  const [submitting, setSubmitting] = useState(false);

  return (
    <Button
      label={"Sign out"}
      variant={"outline"}
      loading={submitting}
      {...otherProps}
      onClick={async (e) => {
        setSubmitting(true);
        try {
          onClick?.(e);
          if (!auth) {
            throw new Error("Missing auth");
          }
          startHolyLoader();
          await auth.signOut();
          router.replace("/");
        } catch (error) {
          setSubmitting(false);
          throw error;
        }
      }}
    />
  );
};

export default SignOutButton;
