import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cards/cards/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/cards/cards/node_modules/react-async-stateful/esm/react/ReactHooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cards/cards/packages/app/src/components/app/AppHeader/AppHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cards/cards/packages/app/src/components/app/NavTabs/NavTabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cards/cards/packages/app/src/components/app/SiteFooter/SiteFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TabsList","TabsTrigger"] */ "/home/runner/work/cards/cards/packages/app/src/components/common/Tabs/Tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["triggerSearchChange"] */ "/home/runner/work/cards/cards/packages/app/src/lib/ClientHooks.ts");
