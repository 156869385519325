"use client";

import clsx from "clsx";
import { FIRST_PARTY_COOKIES } from "shared/SharedConstants";
import Button from "src/components/common/Button/Button";
import Link from "src/components/common/Link/Link";
import { useCookies } from "src/lib/ClientHooks";
import styles from "./CookieBanner.module.scss";

export interface CookieBannerStylingProps {
  children: string;
}

export const CookieBannerStyling = (props: CookieBannerStylingProps) => {
  const { children } = props;
  return <style data-cookie-banner-styling={true}>{`[data-cookie-banner] {${children}}`}</style>;
};

export interface CookieBannerProps {
  acceptedVersion?: number | string;
}

const COOKIE_VERSION = 2;
const FORCE_VISIBLE = false;

const CookieBanner = (props: CookieBannerProps) => {
  const { acceptedVersion } = props;
  const { getCookie, setCookie } = useCookies();
  const resolvedAcceptedVersion =
    acceptedVersion ?? getCookie(FIRST_PARTY_COOKIES.ACCEPTED_COOKIES_VERSION.name);
  const hasAccepted =
    !FORCE_VISIBLE && resolvedAcceptedVersion?.toString() === COOKIE_VERSION.toString();

  return (
    <>
      <div
        data-cookie-banner={true}
        className={clsx(
          styles.cookieBanner,
          "fixed p-3 text-start flex shadow-lg bg-background rounded gap-2 z-[1000] items-center justify-between",
          hasAccepted && "hidden",
        )}
      >
        <div className={"text-sm"}>
          This website uses cookies to ensure you get the best experience. Read our{" "}
          <Link variant={"hyperlink"} href={"/cookies#policy"}>
            Cookie Policy
          </Link>
          .
        </div>

        <div className={"flex gap-2 mobile:flex-col-reverse"}>
          <Button
            className={"min-w-[110px]"}
            label={"Learn more"}
            size={"small"}
            variant={"outline"}
            href={"/cookies"}
          />
          <Button
            className={"min-w-[70px]"}
            size={"small"}
            label={"Got it"}
            onClick={() => {
              setCookie(FIRST_PARTY_COOKIES.ACCEPTED_COOKIES_VERSION.name, `${COOKIE_VERSION}`, {
                expiresMs: 1000 * 60 * 60 * 24 * 365,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CookieBanner;
