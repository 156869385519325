"use client";

import AppLayout from "src/components/app/AppLayout/AppLayout";
import ErrorPage, { type ErrorPageProps } from "src/components/app/ErrorPage/ErrorPage";

export interface GlobalErrorPageProps extends ErrorPageProps {}

const GlobalErrorPage = (props: GlobalErrorPageProps) => {
  const { ...otherProps } = props;

  return (
    <AppLayout>
      <ErrorPage {...otherProps} />
    </AppLayout>
  );
};

export default GlobalErrorPage;
