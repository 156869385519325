"use client";

import clsx from "clsx";
import Button, { type ButtonProps } from "src/components/common/Button/Button";
import Tooltip from "src/components/common/Tooltip/Tooltip";
import styles from "./IconButton.module.scss";

export interface IconButtonProps extends ButtonProps {}

const IconButton = (props: IconButtonProps) => {
  const { className, variant, label, ...otherProps } = props;
  return (
    <Tooltip>
      <Tooltip.Trigger asChild={true}>
        <Button
          label={label}
          className={clsx(styles.iconButton, styles[`variant-${variant ?? "normal"}`], className)}
          variant={variant}
          {...otherProps}
        />
      </Tooltip.Trigger>
      <Tooltip.Content>{label}</Tooltip.Content>
    </Tooltip>
  );
};

export default IconButton;
