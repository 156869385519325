import clsx from "clsx";
import NextLink, { type LinkProps as NextLinkProps } from "next/link";
import React, { type HTMLAttributes } from "react";
import styles from "./Link.module.scss";

export interface LinkProps extends Omit<NextLinkProps, "href">, HTMLAttributes<HTMLAnchorElement> {
  href?: string;
  variant?: "hyperlink" | "bare" | "minimal";
  disabled?: boolean;
  target?: "_blank";
}

const Link = (props: LinkProps) => {
  const { href, disabled, target, variant, className, children, ...otherProps } = props;
  const Tag = href ? (disabled ? "span" : NextLink) : "button";
  return (
    <Tag
      // @ts-ignore
      href={href}
      disabled={disabled}
      target={target}
      aria-disabled={disabled}
      className={clsx(
        styles.link,
        variant === "hyperlink" && styles.hyperlink,
        variant === "minimal" && styles.minimal,
        className,
      )}
      {...otherProps}
    >
      {children}
    </Tag>
  );
};

export default Link;
