import { isRequestError } from "api-def";
import baseDebounce from "debounce";
import type { Router } from "next/router";
import { AsyncState } from "react-async-stateful";
import { isDarkColor } from "shared/SharedUtils";
import { triggerSearchChange } from "src/lib/ClientHooks";
import { getRequestStore } from "src/lib/RequestState";

export type { DebouncedFunction } from "debounce";

export const isDeepEqual = (a: any, b: any) => {
  if (a === b) {
    return true;
  }
  return JSON.stringify(a) === JSON.stringify(b);
};

const FOCUSABLE_SELECTOR = [
  "button",
  "[href]",
  "input",
  "select",
  "textarea",
  "[tabindex]",
  "[contenteditable]",
]
  .map((selector) => `${selector}:not([tabindex='-1'])`)
  .join(", ");

export const getFocusableElement = (
  element: HTMLElement | null,
): (HTMLElement & { focus: () => void }) | null => {
  if (!element) {
    return null;
  }
  return element.querySelector(FOCUSABLE_SELECTOR);
};

export const toAsyncState = <T>(
  func: () => T,
): T extends Promise<any> ? Promise<AsyncState<Awaited<T>>> : AsyncState<T> => {
  const state = AsyncState.create<T>();
  try {
    const valueOrPromise = func();
    if (valueOrPromise instanceof Promise) {
      return valueOrPromise.then(
        (value) => AsyncState.resolve(state, value),
        (error) => AsyncState.reject(state, error),
      ) as any;
    }
    return AsyncState.resolve(state, valueOrPromise) as any;
  } catch (error: any) {
    return AsyncState.reject(state, error) as any;
  }
};

export const toTitleCase = <S extends string | undefined | null>(string: S): S => {
  if (!string) {
    return string;
  }
  // @ts-ignore
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isTokenExpiredError = (error: any) => {
  if (error.message === "FORCE_REFRESH") {
    return true;
  }
  if (isRequestError(error)) {
    return error.response?.data?.code === "auth/token-expired";
  }
  return false;
};

const VAR_REGEX = /var\((--[^)]+)\)/;

export const getTextColorFromBackground = (backgroundColor: string | undefined | null) => {
  if (backgroundColor) {
    // check for css variable
    const match = VAR_REGEX.exec(backgroundColor);
    if (match) {
      const variable = match[1];
      return `var(${variable}-foreground)`;
    }

    return isDarkColor(backgroundColor) ? "var(--color-light)" : "var(--color-dark)";
  }
  return undefined;
};

export const getFromParam = (from: string) => {
  if (!from.startsWith("/")) {
    throw new Error("From param must start with a /");
  }
  return encodeURIComponent(btoa(from));
};

export const debounce = baseDebounce;

export const getQueryParam = (key: string) => {
  let search: string;
  if (typeof window === "undefined") {
    const requestStore = getRequestStore();
    search = requestStore.url.search;
  } else {
    search = window.location.search;
  }
  return new URLSearchParams(search).get(key);
};

export const queryParentSelector = (element: HTMLElement, selector: string | string[]) => {
  let targetElement: HTMLElement | null = element;

  while (targetElement) {
    if (Array.isArray(selector)) {
      if (selector.some((s) => targetElement?.matches(s))) {
        return true;
      }
    } else if (targetElement.matches(selector)) {
      return true;
    }

    targetElement = targetElement.parentElement;
  }

  return false;
};

export interface SetQueryParamOptions {
  type?: "push" | "replace";
}

export const setQueryParam = (
  key: string,
  value: string | null | undefined,
  options?: SetQueryParamOptions,
) => {
  if (typeof window === "undefined") {
    return;
  }
  const search = new URLSearchParams(window.location.search);
  if (value === null || value === undefined) {
    search.delete(key);
  } else {
    search.set(key, value);
  }

  if (search.toString() === window.location.search.replace(/^\?/, "")) {
    return;
  }

  // @ts-ignore
  const router: Router = window.next.router;
  if (!router) {
    window.history.replaceState(window.history.state, "", `${window.location.pathname}?${search}`);
  } else {
    router[options?.type === "push" ? "push" : "replace"](`${window.location.pathname}?${search}`);
  }

  triggerSearchChange();
};
