import type { Fit } from "./SharedTypes";

export type CookieType = "functional" | "analytics" | "marketing" | "essential";

export interface CookieInfo {
  name: string;
  description: string;
  expirationDescription: string;
  type: CookieType;
  providerName?: string;
}

export const FIRST_PARTY_COOKIES = {
  EVER_LOGGED_IN: {
    name: "GL_everLoggedIn",
    description: "Indicates if the user has ever logged in to the app",
    expirationDescription: "10 years",
    type: "essential",
    providerName: "GreenLinks",
  },
  CURRENT_BOARD: {
    name: "GL_currentBoard",
    description: "Indicates the current board the user is viewing",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
  ID_TOKEN: {
    name: "GL_idToken",
    description: "User authentication token",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
  REFRESH_TOKEN: {
    name: "GL_refreshToken",
    description: "User refresh token",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
  TRIAL_TOKEN: {
    name: "GL_trialToken",
    description: "User trial token",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
  VISITOR_DATA: {
    name: "GL_visitorData",
    description: "Visitor data",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
  ID_TOKEN_EXPIRES_AT: {
    name: "GL_idTokenExpiresAt",
    description: "User authentication token expiration time",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
  PLAN_SUCCESSFULLY_CHANGED: {
    name: "GL_planSuccessfullyChanged",
    description: "Indicates if the user has successfully changed their plan",
    expirationDescription: "24 hours",
    type: "essential",
    providerName: "GreenLinks",
  },
  ACCEPTED_COOKIES_VERSION: {
    name: "GL_acceptedCookiesVersion",
    description: "Indicates the version of the cookies the user has accepted",
    expirationDescription: "1 year",
    type: "essential",
    providerName: "GreenLinks",
  },
  PRE_RENDERED_WIDTH: {
    name: "GL_preRenderedWidth",
    description: "Indicates the viewport width of the user",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
  ONBOARDING_DATA: {
    name: "GL_onboardingData",
    description: "Various flags for the onboarding process",
    expirationDescription: "Session",
    type: "essential",
    providerName: "GreenLinks",
  },
} as const satisfies Record<string, CookieInfo>;

export const THIRD_PARTY_COOKIES = {
  STRIPE: {
    name: "__stripe_mid",
    description: "Payment processing",
    expirationDescription: "Session",
    type: "essential",
    providerName: "Stripe",
  },
} as const satisfies Record<string, CookieInfo>;

export const PLAN_CHANGE_TIMEOUT_HOURS = 24;

export const COOKIE_NEVER_EXPIRES = 60 * 60 * 24 * 365 * 10; // 10 years

export const SHOW_ADS_IN_MS = 1000 * 60 * 60 * 24 /* hours */ * 2 /* days */;
export const SHOW_ADS_IN_STRING = "14 days";

export const ENABLE_MOBILE_ONLY_BREAKPOINT = true;

export const MAX_IMAGE_WIDTH = 1920;
export const MAX_IMAGE_HEIGHT = 1080;

export const PAYMENT_RETURN_TYPES = ["edit"] as const;
export type PaymentReturnType = (typeof PAYMENT_RETURN_TYPES)[number];

// @ts-ignore
export const IS_BACKEND = typeof process === "undefined" || typeof WebSocketPair !== "undefined";

export const MIN_SLUG_LENGTH = 3;
export const MAX_SLUG_LENGTH = 24;

export const BLURB = "You, all in one link";

export const DEFAULT_FIT: Fit = "contain";
