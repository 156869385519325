import { Slottable } from "@radix-ui/react-slot";
import type React from "react";

export interface StatusPanelProps {
  asChild?: true;
  title: string;
  children?: React.ReactNode;
}

const StatusPanel = (props: StatusPanelProps) => {
  const { asChild, title, children, ...otherProps } = props;
  return (
    <div className={"w-full p-10 flex-1 flex h-full justify-center items-center relative"}>
      <div className={"w-[500px] max-w-[100%] min-w-[50%]"}>
        <h1 className={"self-center"}>{title}</h1>
        <Slottable>{children}</Slottable>
      </div>
    </div>
  );
};

export default StatusPanel;
