"use client";
import { useEffect, useLayoutEffect } from "react";
import { setInitialRenderDone } from "src/lib/ClientHooks";
import { setQueryParam } from "src/lib/MiscFrontendUtils";

const RESTORE_FROM_BF_CACHE_LISTENERS = new Set<() => void>();

export const useRestoredFromBackForwardCacheListener = (callback: () => void) => {
  useEffect(() => {
    RESTORE_FROM_BF_CACHE_LISTENERS.add(callback);
    return () => {
      RESTORE_FROM_BF_CACHE_LISTENERS.delete(callback);
    };
  }, []);
};

if (typeof window !== "undefined") {
  window.addEventListener("pageshow", (event) => {
    if (event.persisted) {
      if (process.env.NODE_ENV === "development") {
        console.log("This page was restored from the bfcache.");
      }
      requestAnimationFrame(() => {
        setQueryParam("transition", null);
        for (const callback of RESTORE_FROM_BF_CACHE_LISTENERS) {
          callback();
        }
      });
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log("This page was loaded normally.");
      }
    }
  });
}

const InitialLayoutDetector = () => {
  useLayoutEffect(() => {
    setInitialRenderDone();
  });
  return null;
};

export default InitialLayoutDetector;
