"use client";

import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";

const ROUTE_CHANGE_LISTENERS_ONCE = new Set<() => void>();
const ROUTE_CHANGE_LISTENERS_ALWAYS = new Set<() => void>();

export const onNextRouteChangeOnce = (callback: () => void) => {
  ROUTE_CHANGE_LISTENERS_ONCE.add(callback);
};

export const RouterChangeListener = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (ROUTE_CHANGE_LISTENERS_ONCE.size > 0) {
      for (const callback of ROUTE_CHANGE_LISTENERS_ONCE) {
        callback();
      }
      ROUTE_CHANGE_LISTENERS_ONCE.clear();
    }
    if (ROUTE_CHANGE_LISTENERS_ALWAYS.size > 0) {
      for (const callback of ROUTE_CHANGE_LISTENERS_ALWAYS) {
        callback();
      }
    }
  }, [pathname]);

  return null;
};

export const useNextRouteChangeListener = (callback: () => void) => {
  useEffect(() => {
    ROUTE_CHANGE_LISTENERS_ALWAYS.add(callback);
    return () => {
      ROUTE_CHANGE_LISTENERS_ALWAYS.delete(callback);
    };
  }, []);
};
