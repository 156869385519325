"use client";

import clsx from "clsx";
import type React from "react";
import { IconBoard } from "shared/Icons";
import type { AuthState } from "shared/SharedTypes";
import Logo from "src/components/app/Logo/Logo";
import Button from "src/components/common/Button/Button";
import Link from "src/components/common/Link/Link";

export interface AppHeaderProps {
  children?: React.ReactNode;
  authState?: AuthState | null;
}

const AppHeader = (props: AppHeaderProps) => {
  const { children, authState } = props;
  return (
    <header className={"flex justify-between relative"}>
      <div className={"flex gap-6 desktop:items-end mobile:flex-col "}>
        <Link href={authState ? "/app" : "/"}>
          <Logo className={"md:text-4xl text-3xl"} />
        </Link>

        {children}
      </div>
      <div className={"absolute right-0 top-0"}>
        <Button
          label={"Edit board"}
          color={"light"}
          href={"/app/edit"}
          className={clsx("border !inline-flex min-w-[100px]")}
        >
          <IconBoard />
          <span>Edit</span>
        </Button>
      </div>
    </header>
  );
};

export default AppHeader;
