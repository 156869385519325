"use client";

import { useEffect } from "react";
import LoadingSpinner from "src/components/common/LoadingSpinner/LoadingSpinner";
import Overlay from "src/components/common/Overlay/Overlay";
import { useQueryParams } from "src/lib/ClientHooks";
import { onNextRouteChangeOnce, useNextRouteChangeListener } from "src/lib/RouterChangeListener";

interface TransitionInfo {
  heading: string;
  loading: boolean;
  persist?: boolean;
}

export const TRANSITIONS = {
  loggingIn: {
    heading: "Signing in",
    loading: true,
    persist: true,
  },
  loggingOut: {
    heading: "Signing out",
    loading: true,
  },
} as const satisfies Record<string, TransitionInfo>;

const GlobalTransitions = () => {
  const { queryParams, setQueryParam } = useQueryParams();
  const transition = queryParams.get("transition");

  const transitionInfo = TRANSITIONS[transition ?? ""];

  useEffect(() => {
    // if the user went back or forward in history, we need to reset the transition
    const callback = () => {
      setQueryParam("transition", null);
    };
    window.addEventListener("popstate", callback);
    return () => {
      window.removeEventListener("popstate", callback);
    };
  }, []);

  useEffect(() => {
    if (transition) {
      document.body.dataset.transition = transition;
    } else {
      delete document.body.dataset.transition;
    }
  }, [transition]);

  useEffect(() => {
    if (!transitionInfo?.persist) {
      setQueryParam("transition", null);
    }
  }, []);

  return (
    <>
      <style>
        {Object.keys(TRANSITIONS)
          .map(
            (key) => `[data-transition="${key}"] [data-global-overlay="${key}"] {
          pointer-events: all;
          visibility: visible;
          opacity: 1;
         }`,
          )
          .join(" ")}
      </style>
      {Object.entries(TRANSITIONS).map(([key, data]) => (
        <Overlay data-global-overlay={key} visible={false} key={key} className={"!fixed"}>
          <h2 className={"text-2xl"}>{data.heading}</h2>
          {data.loading ? <LoadingSpinner size={48} /> : null}
        </Overlay>
      ))}
    </>
  );
};

export default GlobalTransitions;
